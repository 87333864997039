import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link, navigate} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../../src/components/layout/layout';
import {Translate} from '../../components/translations';
import {DomainForm} from '../../components/form/domain-form';
import {PageHero} from '../../components/page-hero';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import W3CChckerTool from '../../components/scan-tools/w3c-check';
import {Modal} from '../../components/modal';
import Button from '../../components/button';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';
import w3cCheckerImg from '../../img/home-tools/w3c-checker.png';

export default (props: ScanResultsPageProps) => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <Layout>
      <PageHero
        title={'W3C Markup Validation Tool'}
        subtitle={'Check the markup (HTML, XHTML, …) of any web page validating against the W3C standard.'}
      >
        <DomainForm value={url} onSubmit={u => navigate(`/w3c-validator/${encodeURIComponent(u)}`)} />
      </PageHero>
      <Section>
        {isMobile ? (
          <>
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('W3CCheckerTool', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <W3CChckerTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={w3cCheckerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            <strong>What is W3C Markup Validation and why is it important?</strong>
                          </p>
                          <p>
                            Most Web documents are written using markup languages, such as HTML or XHTML. These
                            languages are defined by technical specifications, which usually include a machine-readable
                            formal grammar. The act of checking a document against these constraints is called
                            validation, and this is what the W3C Markup Validation Tool does.
                          </p>
                        </div>

                        <div className="col-md-12">
                          <p>
                            Markup validation is an important step towards ensuring the technical quality of web pages.
                            The Markup Validator is a free service that helps you to check the validity of websites or
                            web pages separately.
                          </p>
                          <p>
                            This validator can process documents written in most markup languages. Supported document
                            types include the HTML and XHTML, MathML, SMIL and SVG. As for every computer language,
                            these have their own grammar, vocabulary, and syntax, and every document written with these
                            languages are supposed to follow these rules.
                          </p>
                          <p>
                            <em>
                              The Markup Validation Service is a validator by the World Wide Web Consortium (
                              <a href="https://hexometer.com/glossary/">W3C</a>).
                            </em>
                          </p>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <ToolTabs active="w3c-check" url={url} />
            </div>
          </>
        ) : (
          <>
            <div className="col-md-3">
              <ToolTabs active="w3c-check" url={url} />
            </div>
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('W3CCheckerTool', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <W3CChckerTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={w3cCheckerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            <strong>What is W3C Markup Validation and why is it important?</strong>
                          </p>
                          <p>
                            Most Web documents are written using markup languages, such as HTML or XHTML. These
                            languages are defined by technical specifications, which usually include a machine-readable
                            formal grammar. The act of checking a document against these constraints is called
                            validation, and this is what the W3C Markup Validation Tool does.
                          </p>
                        </div>

                        <div className="col-md-12">
                          <p>
                            Markup validation is an important step towards ensuring the technical quality of web pages.
                            The Markup Validator is a free service that helps you to check the validity of websites or
                            web pages separately.
                          </p>
                          <p>
                            This validator can process documents written in most markup languages. Supported document
                            types include the HTML and XHTML, MathML, SMIL and SVG. As for every computer language,
                            these have their own grammar, vocabulary, and syntax, and every document written with these
                            languages are supposed to follow these rules.
                          </p>
                          <p>
                            <em>
                              The Markup Validation Service is a validator by the World Wide Web Consortium (
                              <a href="https://hexometer.com/glossary/">W3C</a>).
                            </em>
                          </p>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};
