import React, {Fragment} from 'react';
import {ResultCard} from '../../result-card';
import {W3CQueryResultProps} from '../../../types';
import {Translate} from '../../translations';
import {ProgressBar} from '../../progressBar';

export default ({url, validatorErrors, _error}: W3CQueryResultProps) => (
  <Fragment>
    <div className="row">
      {!url && !_error && (
        <div className="col-md-12 text-center">
          <div className="text-center">
            <ProgressBar />
          </div>
          <div className="text-center">
            <Translate name="TOOL_FULL_LOADING_TEXT" />
          </div>
          <p>&nbsp;</p>
        </div>
      )}
    </div>
    {url && (
      <div className="row">
        <div className="col-md-12">
          {validatorErrors && (
            <ResultCard
              title={
                <>
                  <Translate name="ALL_W3C_ERRORS" /> {validatorErrors.length > 0 && `(${validatorErrors.length})`}
                </>
              }
              items={[
                {
                  description: (
                    <div>
                      {validatorErrors.length > 0 ? (
                        validatorErrors.map(w3cError => (
                          <div key={w3cError.extract}>
                            <h5>
                              Line {w3cError.position.lastLine} Column {w3cError.position.firstColumn}
                            </h5>
                            <p style={{wordBreak: 'break-word'}}>
                              <span title={w3cError.extract} className="badge badge-info">
                                {w3cError.extract}
                              </span>
                            </p>
                            <p>
                              <span className="text-muted">{w3cError.explanation}</span>
                            </p>
                          </div>
                        ))
                      ) : (
                        <Translate name="NO_ERRORS_FOUND" />
                      )}
                    </div>
                  ),
                },
              ]}
            />
          )}
        </div>
      </div>
    )}
  </Fragment>
);
