import React, {FunctionComponent} from 'react';
import {navigate} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ToolCardProps} from '../../types';
import {Translate} from '../translations';
import DetailsButton from '../../components/button';
import './styles.scss';

const Button = ({
  actionHref,
  href,
  actionText,
  onActionClick,
}: {
  actionHref?: string;
  href?: string;
  actionText?: string;
  onActionClick?: (event: React.SyntheticEvent) => void;
}) => {
  const text = <Translate name={actionText || ''} />;
  if (!href && actionHref) {
    return (
      <DetailsButton transparent onClick={() => navigate(actionHref)}>
        {text}
      </DetailsButton>
    );
  } else if (href && !actionHref) {
    return (
      <DetailsButton
        transparent
        onClick={event => {
          event && event.stopPropagation();
          return event && onActionClick && onActionClick(event);
        }}
      >
        {text}
      </DetailsButton>
    );
  } else {
    return null;
  }
};

export const ToolCard: FunctionComponent<ToolCardProps> = props => (
  <div className="tool-card" onClick={props.onClick}>
    <figure className="text-center tool-card-figure">
      {typeof props.image === 'string' ? <img src={props.image} alt="" /> : props.image}
    </figure>
    <div className="joblisting_text">
      <div className="job_list_table">
        <div className="jobsearch-table-cell">
          <div className="mp_result">
            {typeof props.title === 'string' ? <Translate name={props.title} /> : props.title}
          </div>
          {props.subTitle && (
            <div className="subtitle">
              {typeof props.subTitle === 'string' ? <Translate name={props.subTitle} /> : props.subTitle}
            </div>
          )}
        </div>
        <div className="jobsearch-table-cell">
          <div className="jobsearch-job-userlist">
            {props.actionText && (
              <Button
                actionHref={props.actionHref}
                href={props.href}
                actionText={props.actionText}
                onActionClick={props.onActionClick}
              />
            )}
            {props.icon ? (
              <div className="like-btn">
                <a className="shortlist">
                  <FontAwesomeIcon icon={props.icon} color={props.icon === 'check' ? '#2ecc71' : '#ff0000'} />
                </a>
              </div>
            ) : props.rightIconImage && typeof props.rightIconImage === 'string' ? (
              <div className="right-img">
                <img src={props.rightIconImage} />
              </div>
            ) : (
              props.rightIconImage
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
