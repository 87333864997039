import React, { FunctionComponent } from 'react';
import { ToolResultProps } from '../../../types';
import {W3C_CHECK_SUBSCRIPTION} from "../../../graphql/subscriptions";
import SimpleCard from "./simple-card";
import FullResultCard from "./full-result";
import BaseToolWrap from "../BaseToolWrap";

const BrokenLinksTool: FunctionComponent<ToolResultProps> = ({ url, sessionToken, fullResult, onResult, handleScanLimitCheck }) => (
  <BaseToolWrap
    query={W3C_CHECK_SUBSCRIPTION}
    FullResult={FullResultCard}
    SimpleResult={SimpleCard}
    url={url}
    sessionToken={sessionToken}
    fullResult={fullResult}
    onResult={onResult}
    handleScanLimitCheck={handleScanLimitCheck}
  />
);

export default BrokenLinksTool;
