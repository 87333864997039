import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCode, faCog } from '@fortawesome/free-solid-svg-icons';
import {ToolCard} from "../../tool-card";
import { W3CQueryResultProps } from "../../../types";

export default ({ url, validatorErrors, baseUrl }: W3CQueryResultProps) => (
  <ToolCard
    image={<FontAwesomeIcon icon={url ? faCode : faCog} size="2x" spin={!url} />}
    title="W3C_VALIDATION_CHECK_TITLE"
    subTitle={url ? (
      <div>
        <p><strong>W3C Errors Found - </strong>{validatorErrors ? validatorErrors.length : 0}</p>
      </div>
    ) : ''}
    actionText='SHOW_MORE'
    actionHref={`/w3c-validator/${baseUrl}`}
  />
);
